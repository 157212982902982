import "./widget.scss";
import NightShelterIcon from "@mui/icons-material/NightShelter";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import BedIcon from "@mui/icons-material/Bed";

const Widget = ({ type, stats }) => {
  let data = {
    title: "",
    maleCount: 0,
    femaleCount: 0,
    isMoney: false,
    icon: (
      <NightShelterIcon
        className="icon"
        style={{
          backgroundColor: "rgba(218, 165, 32, 0.2)",
          color: "goldenrod",
        }}
      />
    ),
  };

  // Setting data based on the widget type
  switch (type) {
    case "available":
      data = {
        ...data,
        title: "AVAILABLE ROOMS",
        maleCount: stats.nonFullyBookedMale,
        femaleCount: stats.nonFullyBookedFemale,
      };
      break;

    case "full":
      data = {
        ...data,
        title: "FULLY BOOKED ROOMS",
        maleCount: stats.fullyBookedMale,
        femaleCount: stats.fullyBookedFemale,
      };
      break;
    case "availableBeds":
      data = {
        ...data,
        title: "AVAILABLE BEDS",
        icon: (
          <LocalHotelIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
        maleCount: stats.availableBedMale,
        femaleCount: stats.availableBedFemale,
      };
      break;
    case "bookedBeds":
      data = {
        ...data,
        title: "BOOKED BEDS",
        icon: (
          <BedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
        maleCount: stats.bookedBedMale,
        femaleCount: stats.bookedBedFemale,
      };
      break;

    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <div className="counter incounter">
          <p className="widget_info">
            Total: {data.maleCount + data.femaleCount}
          </p>
          <p className=" widget_info">Males: {data.maleCount}</p>
          <p className="widget_info">Females: {data.femaleCount}</p>
        </div>
      </div>
      <div className="right">
        {/* <div className="percentage positive">
          <KeyboardArrowUpIcon />
          {/* Example percentage calculation, you can replace this with dynamic values */}
        {/* 20% */}
        {/* </div> */}
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
