import Home from "./pages/home/Home";
import Login from "./pages/login/Login";

import axios from "axios";
import New from "./pages/new/New";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { adminInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthProvider } from "./context/AuthContext";
import { hotelColumns } from "./datatablesource";
import NewHotel from "./pages/newHotel/NewHotel";
import NewRoom from "./pages/newRoom/NewRoom";
import List from "./components/table/Table";
import AllUsers from "./pages/users/AllUsers";
import AllRooms from "./pages/rooms/AllRooms";
import AllBookings from "./pages/bookings/AllBookings";
import Payments from "./pages/payments/Payments";
import ProtectedRoute from "./ProtectedRoute";
import Profile from "./pages/Profile/Profile";

axios.defaults.baseURL = "https://back.showers-hostel.com/";
// axios.defaults.baseURL = "http://localhost:8080/";
// axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_TEST}`;

function App() {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />

              <Route path="users">
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <AllUsers />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="newUser"
                  element={
                    <ProtectedRoute>
                      <New
                        inputs={userInputs}
                        title="Add New User"
                        pre={false}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="newAdmin"
                  element={
                    <ProtectedRoute>
                      <New
                        inputs={adminInputs}
                        title="Add New User"
                        pre={true}
                      />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="hostels">
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <List columns={hotelColumns} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="new"
                  element={
                    <ProtectedRoute>
                      <NewHotel />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="rooms">
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <AllRooms />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="new"
                  element={
                    <ProtectedRoute>
                      <NewRoom />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="bookings">
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <AllBookings />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="new"
                  element={
                    <ProtectedRoute>
                      <NewRoom />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="payments">
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <Payments />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path="profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route path="login" element={<Login />} />
            </Route>
            <Route
              path="*"
              element=<div>THIS PAGE DOES NOT EXIST !!!.... sorry :)</div>
            />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
