export const userInputs = [
  {
    id: "username",
    label: "Username",
    name: "username",
    type: "text",
    placeholder: "Enter username",
  },
  {
    id: "email",
    label: "Email",
    name: "email",
    type: "email",
    placeholder: "Enter email",
  },

  {
    id: "firstname",
    label: "First Name",
    name: "firstname",
    type: "text",
    placeholder: "Enter first name",
  },
  {
    id: "lastname",
    label: "Last Name",
    name: "lastname",
    type: "text",
    placeholder: "Enter last name",
  },
  {
    id: "level_",
    label: "Level",
    name: "level_",
    type: "text",
    placeholder: "Enter level",
  },
  {
    id: "course",
    label: "Course",
    name: "course",
    type: "text",
    placeholder: "Enter course",
  },
  {
    id: "phone",
    label: "Phone",
    name: "phone",
    type: "text",
    placeholder: "Enter phone number",
  },
  {
    id: "password",
    label: "Password",
    name: "password",
    type: "password",
    placeholder: "Enter password",
  },
];

export const adminInputs = [
  {
    id: "username",
    label: "Username",
    name: "username",
    type: "text",
    placeholder: "Enter username",
  },
  {
    id: "email",
    label: "Email",
    name: "email",
    type: "email",
    placeholder: "Enter email",
  },
  {
    id: "phoneNumber",
    label: "Phone Number",
    name: "phoneNumber",
    type: "text",
    placeholder: "Enter phone number",
  },
  {
    id: "password",
    label: "Password",
    name: "password",
    type: "password",
    placeholder: "Enter password",
  },
];

export const productInputs = [
  {
    id: 1,
    label: "Title",
    type: "text",
    placeholder: "Apple Macbook Pro",
  },
  {
    id: 2,
    label: "Description",
    type: "text",
    placeholder: "Description",
  },
  {
    id: 3,
    label: "Category",
    type: "text",
    placeholder: "Computers",
  },
  {
    id: 4,
    label: "Price",
    type: "text",
    placeholder: "100",
  },
  {
    id: 5,
    label: "Stock",
    type: "text",
    placeholder: "in stock",
  },
];

export const hotelInputs = [
  {
    id: "name",
    label: "Hostel Name",
    name: "name",
    type: "text",
    placeholder: "Enter hostel name",
  },
  {
    id: "address",
    label: "Address",
    name: "address",
    type: "text",
    placeholder: "Enter address",
  },
  {
    id: "photos",
    label: "Photos",
    name: "photos",
    type: "text",
    placeholder: "Enter photos JSON",
  },
  {
    id: "title",
    label: "Title",
    name: "title",
    type: "text",
    placeholder: "Enter title",
  },
  {
    id: "description",
    label: "Description",
    name: "description",
    type: "text",
    placeholder: "Enter description",
  },
];

export const roomInputs = [
  {
    id: "hostel_id",
    label: "Hostel ID",
    name: "hostel_id",
    type: "number",
    placeholder: "Enter hostel ID",
  },
  {
    id: "title",
    label: "Title",
    name: "title",
    type: "text",
    placeholder: "Enter room title",
  },
  {
    id: "price",
    label: "Price",
    name: "price",
    type: "number",
    placeholder: "Enter price",
  },
  {
    id: "maxPeople",
    label: "Max People",
    name: "maxPeople",
    type: "number",
    placeholder: "Enter max people",
  },
  {
    id: "description",
    label: "Description",
    name: "description",
    type: "text",
    placeholder: "Enter description",
  },
  {
    id: "roomNumber",
    label: "Room Number",
    name: "roomNumber",
    type: "number",
    placeholder: "Enter room number",
  },
  {
    id: "floor",
    label: "Floor",
    name: "floor",
    type: "number",
    placeholder: "Enter floor",
  },
  {
    id: "amenities",
    label: "Amenities",
    name: "amenities",
    type: "text",
    placeholder: "Enter amenities JSON",
  },
  {
    id: "isAvailable",
    label: "Is Available",
    name: "isAvailable",
    type: "checkbox",
    placeholder: "Is available",
  },
];
