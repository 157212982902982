import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import { useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Table from "../../components/table/Table";

const Home = () => {
  useEffect(() => {
    document.title = "Dashboard - Admin";
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className="home">
      <div className="showSidebar">
        {" "}
        <Sidebar />
      </div>
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget type="bookings" />
          <Widget type="rooms" />

          <Widget type="payments" />
        </div>
        <div className="charts">
          <Featured />
          <Chart title="Last 5 Months (Revenue)" aspect={3 / 1} />
        </div>
        <div className="listContainer">
          <div className="listTitle">Latest Transactions</div>
          <Table />
        </div>
      </div>
    </div>
  );
};

export default Home;
