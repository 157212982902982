import React, { useState, useEffect } from "react";
import { Edit, Save } from "@mui/icons-material";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "./userprofile.css";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import ClearIcon from "@mui/icons-material/Clear";

import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";

const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { user, updateUser, loading } = useAuth();
  const [editedUser, setEditedUser] = useState(user);

  const [error, setError] = useState(null);
  const [updateError, setUpdateError] = useState(null);

  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    document.title = "Profile - Showers Hostel";
  }, []);

  useEffect(() => {
    setEditedUser(user);
  }, [user]);

  const validateInput = (field, value) => {
    switch (field) {
      case "username":
        return value.length >= 0 && value.length <= 15;
      case "email":
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
      case "phone":
        return /^\+?[1-10]\d{1,14}$/.test(value);
      default:
        return true;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (validateInput(name, value)) {
      setEditedUser((prev) => ({ ...prev, [name]: value }));
      setUpdateError(null);
    } else {
      setUpdateError(`Invalid ${name}. Please check your input.`);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setPreviewImage(null);
    }
  };

  const uploadImage = async () => {
    if (!file) return null;

    const data = new FormData();
    data.append("image", file);

    try {
      const imgbbResponse = await fetch(
        "https://api.imgbb.com/1/upload?key=1990469f9237fb59e7268465029982fa",
        {
          method: "POST",
          body: data,
        }
      );

      const imgbbData = await imgbbResponse.json();

      if (imgbbData.status === 200) {
        return imgbbData.data.url;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (err) {
      console.error("Error uploading image:", err);
      setUpdateError("Failed to upload image. Please try again.");
      return null;
    }
  };

  const toggleEdit = async () => {
    if (isEditing) {
      setIsSaving(true);
      try {
        let imageUrl = editedUser.img;
        if (file) {
          imageUrl = await uploadImage();
          if (!imageUrl) {
            setIsSaving(false);
            return;
          }
        }

        const updatedUserData = { ...editedUser, img: imageUrl };
        const response = await axios.patch(
          `/api/users/admin/${user.id}`,
          updatedUserData
        );
        const updatedUser = { ...user, ...response.data, img: imageUrl };
        updateUser(updatedUser);
        setEditedUser(updatedUser);
        setIsEditing(false);
        setUpdateError(null);
        setFile(null);
        setPreviewImage(null);
      } catch (error) {
        console.error("Failed to update user:", error);
        setUpdateError(
          error.response?.data?.message ||
            "Failed to update user information. Please try again."
        );
      } finally {
        setIsSaving(false);
      }
    } else {
      setIsEditing(true);
    }
  };

  const handleEditChange = () => {
    setIsEditing(false);
    setEditedUser(user);
    setFile(null);
    setPreviewImage(null);
  };

  const handleComplaint = async (e) => {
    e.preventDefault();
    const complaint = e.target.complaint.value;
    try {
      await axios.post("/api/complaints", { userId: user.id, complaint });
      alert("Complaint submitted successfully");
      e.target.reset();
    } catch (error) {
      console.error("Failed to submit complaint:", error);
      alert("Failed to submit complaint. Please try again.");
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <DotLottieReact
          src="https://lottie.host/83993069-1b11-4676-a63c-40334c08fc2e/Lu23ELkJ04.lottie"
          loop
          autoplay
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="user-profile">
        <div className="personal-info">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className="single">
      <div className="showSidebar">
        <Sidebar />
      </div>

      <div className="singleContainer">
        {" "}
        <Navbar />
        <div className="user-profile">
          <div className="profile-container">
            <div className="personal-info">
              <div className="profile-header">
                {isEditing ? (
                  <div className="file-upload">
                    <label htmlFor="file">
                      Profile Image <CloudUploadIcon className="icon" />
                    </label>
                    <input
                      type="file"
                      id="file"
                      name="img"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />{" "}
                    {previewImage && (
                      <img
                        src={previewImage}
                        alt="Preview"
                        className="profile-picture preview"
                      />
                    )}
                    {file && <span>{file.name}</span>}
                  </div>
                ) : (
                  <img
                    src={editedUser.img}
                    alt={editedUser.username}
                    className="profile-picture"
                  />
                )}

                <div className="profile-name-edit">
                  <h2>{editedUser.name}</h2>
                  <button
                    onClick={toggleEdit}
                    className="edit-button"
                    disabled={isSaving}
                  >
                    {isEditing ? isSaving ? "Saving..." : <Save /> : <Edit />}
                    {isEditing ? !isSaving && "Save" : "Edit"}
                  </button>
                  {isEditing && (
                    <button onClick={handleEditChange}>
                      <ClearIcon />
                      Cancel
                    </button>
                  )}
                </div>
              </div>
              {updateError && (
                <div className="error-message">{updateError}</div>
              )}
              <div className="info-fields">
                {["username", "email", "phone"].map((field) => (
                  <div key={field} className="info-field">
                    <label htmlFor={field}>
                      {field.charAt(0).toUpperCase() + field.slice(1)}:
                    </label>
                    {isEditing ? (
                      <input
                        type={
                          field === "email"
                            ? "email"
                            : field === "phone"
                            ? "tel"
                            : "text"
                        }
                        id={field}
                        name={field}
                        value={editedUser[field]}
                        onChange={handleInputChange}
                        disabled={isSaving}
                      />
                    ) : (
                      <span>{editedUser[field]}</span>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="complaint-section" id="contact">
              <h3>Report a Problem</h3>
              <form onSubmit={handleComplaint}>
                <textarea
                  name="complaint"
                  placeholder="Describe your issue here..."
                  required
                ></textarea>
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
