import { useState, useEffect } from "react";
import axios from "axios";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import List from "../../components/table/Table";
import Widget from "../../components/widget/Widget";
import Pagination from "../../components/Pagination";
import "./allpayments.scss";

function Payments() {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentsPerPage] = useState(10);

  useEffect(() => {
    document.title = "Payments - Admin";
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    try {
      const response = await axios.get("/api/rooms/payments/table");
      setPayments(response.data);
    } catch (error) {
      console.error("Failed to fetch payment data:", error);
      setError("Failed to load payments. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Get current payments
  const indexOfLastPayment = currentPage * paymentsPerPage;
  const indexOfFirstPayment = indexOfLastPayment - paymentsPerPage;
  const currentPayments = payments.slice(
    indexOfFirstPayment,
    indexOfLastPayment
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return (
      <div className="loading-container">
        <DotLottieReact
          src="https://lottie.host/83993069-1b11-4676-a63c-40334c08fc2e/Lu23ELkJ04.lottie"
          loop
          autoplay
        />
      </div>
    );
  }

  if (error) {
    return <div className="error-container">Error: {error}</div>;
  }

  return (
    <div className="single">
      <div className="showSidebar">
        <Sidebar />
      </div>
      <div className="singleContainer">
        <Navbar />
        <div className="widgets inrooms">
          <Widget type="bookings" />
          <Widget type="payments" />
        </div>
        <div className="bottom">
          <h1 className="title">All Payments: {payments.length}</h1>
          <List data={currentPayments} tableType="payments" />
          <Pagination
            itemsPerPage={paymentsPerPage}
            totalItems={payments.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default Payments;
