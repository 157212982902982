import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import List from "../../components/table/Table";
import Widget from "../../components/widget/DetailedWidgets";
import NewRoom from "../newRoom/NewRoom";
import ConfirmDialog from "../../components/ConfirmDialog";
import ErrorBoundary from "../../components/ErrorBoundary";
import Pagination from "../../components/Pagination";
import "./allrooms.scss";

const AllRooms = () => {
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [viewTable, setViewTable] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    roomNumber: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [roomsPerPage] = useState(5);
  const [stats, setStats] = useState({
    fullyBookedMale: 0,
    fullyBookedFemale: 0,
    nonFullyBookedMale: 0,
    nonFullyBookedFemale: 0,
    bookedBedMale: 0,
    bookedBedFemale: 0,
    availableBedMale: 0,
    availableBedFemale: 0,
  });

  const successNotif = useCallback((room) => {
    toast.success(`Room ${room} deleted successfully!`);
  }, []);

  const errorNotif = useCallback((message) => {
    toast.error(message);
  }, []);

  useEffect(() => {
    document.title = "Rooms - Admin";
    fetchRoomStatus();
  }, []);

  const handleNewRoom = () => setViewTable((prevState) => !prevState);

  const fetchRoomStatus = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get("/api/bookings/status");
      const data = response.data;
      setRooms(data);

      const calculatedStats = {
        fullyBookedMale: 0,
        fullyBookedFemale: 0,
        nonFullyBookedMale: 0,
        nonFullyBookedFemale: 0,
        bookedBedMale: 0,
        bookedBedFemale: 0,
        availableBedMale: 0,
        availableBedFemale: 0,
      };

      data.forEach((room) => {
        const { booking_status, gender, booking_count, maxPeople } = room;
        const isMale = gender === "Male";
        const isFull = booking_status === "Full";

        if (isFull) {
          calculatedStats[isMale ? "fullyBookedMale" : "fullyBookedFemale"]++;
          calculatedStats[isMale ? "bookedBedMale" : "bookedBedFemale"] +=
            booking_count;
        } else {
          calculatedStats[
            isMale ? "nonFullyBookedMale" : "nonFullyBookedFemale"
          ]++;
          calculatedStats[isMale ? "bookedBedMale" : "bookedBedFemale"] +=
            booking_count;
          calculatedStats[isMale ? "availableBedMale" : "availableBedFemale"] +=
            maxPeople - booking_count;
        }
      });

      setStats(calculatedStats);
    } catch (error) {
      console.error("Failed to fetch room status data:", error);
      setError("Failed to fetch room status. Please try again.");
      errorNotif("Failed to fetch room status. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDeleteConfirmation = (roomNumber) => {
    setConfirmDialog({ isOpen: true, roomNumber });
  };

  const handleDelete = useCallback(
    async (roomNumber) => {
      setIsDeleting(true);
      setConfirmDialog({ isOpen: false, roomNumber: null });
      // Optimistic UI update
      setRooms((prevRooms) =>
        prevRooms.filter((room) => room.roomNumber !== roomNumber)
      );

      try {
        await axios.delete(`/api/rooms/${roomNumber}`);
        successNotif(roomNumber);
        await fetchRoomStatus(); // Refresh room status after deletion
      } catch (error) {
        console.error("Failed to delete room:", error);
        errorNotif("Failed to delete room. Please try again.");
        // Revert optimistic update
        await fetchRoomStatus();
      } finally {
        setIsDeleting(false);
      }
    },
    [fetchRoomStatus, successNotif, errorNotif]
  );

  // Pagination
  const indexOfLastRoom = currentPage * roomsPerPage;
  const indexOfFirstRoom = indexOfLastRoom - roomsPerPage;
  const currentRooms = rooms.slice(indexOfFirstRoom, indexOfLastRoom);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return (
      <div className="loading-container">
        <DotLottieReact
          src="https://lottie.host/83993069-1b11-4676-a63c-40334c08fc2e/Lu23ELkJ04.lottie"
          loop
          autoplay
        />
      </div>
    );
  }

  if (error) {
    return <div className="error-container">Error: {error}</div>;
  }

  return (
    <ErrorBoundary>
      <div className="single">
        <div className="showSidebar">
          <Sidebar />
        </div>
        <div className="singleContainer">
          <Navbar />
          <ToastContainer
            style={{ zIndex: 9999 }}
            position="top-right"
            autoClose={3000}
          />
          <div className="widgets inrooms">
            <Widget type="available" stats={stats} />
            <Widget type="full" stats={stats} />
            <Widget type="availableBeds" stats={stats} />
            <Widget type="bookedBeds" stats={stats} />
          </div>
          <div className="bottom">
            <h1 className="title">All Rooms: {rooms.length}</h1>
            <button className="btn" onClick={handleNewRoom}>
              {viewTable ? "+ New Room" : "View All Rooms"}
            </button>
            {viewTable ? (
              <>
                <List
                  data={currentRooms}
                  tableType="rooms"
                  onDelete={handleDeleteConfirmation}
                />
                <Pagination
                  itemsPerPage={roomsPerPage}
                  totalItems={rooms.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              </>
            ) : (
              <NewRoom back={handleNewRoom} />
            )}
          </div>
          {isDeleting && <div className="loading-overlay">Deleting...</div>}
          <ConfirmDialog
            isOpen={confirmDialog.isOpen}
            title="Delete Room"
            content={`Are you sure you want to delete room ${confirmDialog.roomNumber}?`}
            onConfirm={() => handleDelete(confirmDialog.roomNumber)}
            onCancel={() =>
              setConfirmDialog({ isOpen: false, roomNumber: null })
            }
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default AllRooms;
