import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
// import StoreIcon from "@mui/icons-material/Store";
// import InsertChartIcon from "@mui/icons-material/InsertChart";
// import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
// import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
// import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link } from "react-router-dom";
// import { DarkModeContext } from "../../context/darkModeContext";
import { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import ConfirmDialog from "../ConfirmDialog";

const Sidebar = () => {
  // const { dispatch } = useContext(DarkModeContext);
  const { logout, user } = useAuth();
  const [confirmDialog, setConfirmDialog] = useState(false);
  async function handleLogout() {
    await logout();
  }
  return (
    <div className="sidebar">
      <div className="top">
        <span className="logo">Showers Hostels</span>
      </div>
      <hr />
      <div className="center">
        <ul>
          {user.isAdmin === "admin" && (
            <>
              {" "}
              <p className="title">MAIN</p>
              <Link to="/" style={{ textDecoration: "none" }}>
                <li>
                  <DashboardIcon className="icon" />
                  <span>Dashboard</span>
                </li>
              </Link>
            </>
          )}

          <p className="title">LISTS</p>
          <Link to="/bookings" style={{ textDecoration: "none" }}>
            <li>
              <LocalShippingIcon className="icon" />
              <span>Bookings</span>
            </li>
          </Link>
          <Link to="/rooms" style={{ textDecoration: "none" }}>
            <li>
              <CreditCardIcon className="icon" />
              <span>Rooms</span>
            </li>
          </Link>

          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
          {user.isAdmin === "admin" && (
            <Link to="/payments" style={{ textDecoration: "none" }}>
              <li>
                <AttachMoneyIcon className="icon" />
                <span>Payments</span>
              </li>
            </Link>
          )}

          {/* <p className="title">USEFUL</p>
          <li>
            <InsertChartIcon className="icon" />
            <span>Stats</span>
          </li>
          <li>
            <NotificationsNoneIcon className="icon" />
            <span>Notifications</span>
          </li>
          <p className="title">SERVICE</p>
          <li>
            <SettingsSystemDaydreamOutlinedIcon className="icon" />
            <span>System Health</span>
          </li>
          <li>
            <PsychologyOutlinedIcon className="icon" />
            <span>Logs</span>
          </li>
          <li>
            <SettingsApplicationsIcon className="icon" />
            <span>Settings</span>
          </li> */}
          <p className="title">USER</p>
          <Link to="/profile">
            {" "}
            <li>
              <AccountCircleOutlinedIcon className="icon" />
              <span>Profile</span>
            </li>
          </Link>

          <li>
            <ExitToAppIcon className="icon" />
            <span onClick={() => setConfirmDialog(true)}>Logout</span>
          </li>
        </ul>
      </div>
      <ConfirmDialog
        isOpen={confirmDialog}
        title="Log out"
        content="Are you sure you want to Log Out?"
        onConfirm={handleLogout}
        onCancel={() => setConfirmDialog(false)}
      />
      {/* <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div> */}
    </div>
  );
};

export default Sidebar;
