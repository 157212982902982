import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState, useEffect } from "react";
import axios from "axios";

const New = ({ inputs, title, pre }) => {
  useEffect(() => {
    document.title = "New User - Admin";
  }, []); // Empty dependency array means this effect runs once on mount

  const [file, setFile] = useState(null);
  const [info, setInfo] = useState({});

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("image", file);

    try {
      // Upload image to imgbb
      const imgbbResponse = await fetch(
        "https://api.imgbb.com/1/upload?key=1990469f9237fb59e7268465029982fa",
        {
          method: "POST",
          body: data,
        }
      );

      const imgbbData = await imgbbResponse.json();

      if (imgbbData.status === 200) {
        const newUser = {
          ...info,
          img: imgbbData.data.url,
        };

        const apiEndpoint = pre ? "/auth/owner/register" : "/auth/register";

        // Send newUser data to the backend
        const response = await axios.post(apiEndpoint, newUser);
        console.log("User registered successfully:", response.data);
      } else {
        console.error("Error uploading image to imgbb:", imgbbData);
      }
    } catch (err) {
      console.error("Error during registration:", err);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  name="img"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>

              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label htmlFor={input.id}>{input.label}</label>
                  <input
                    name={input.name}
                    onChange={handleChange}
                    type={input.type}
                    placeholder={input.placeholder}
                    id={input.id}
                  />
                </div>
              ))}
              <button onClick={handleClick}>Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
