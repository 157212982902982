import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./newRoom.scss";

const NewRoom = ({ back }) => {
  const [formData, setFormData] = useState({
    hostelId: 1,
    title: "",
    price: "",
    maxPeople: 1,
    floor: 0,
    description: "",
    gender: "",
    roomNumber: "",
    amenities: {
      wardrobe: false,
      bathroom: false,
      toilet: false,
    },
    isAvailable: true,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        amenities: {
          ...prevData.amenities,
          [name]: checked,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const filteredAmenities = Object.fromEntries(
      Object.entries(formData.amenities).filter(([, value]) => value)
    );

    const roomData = {
      ...formData,
      amenities: JSON.stringify(filteredAmenities),
    };

    try {
      const response = await axios.post("/api/rooms/", roomData);
      toast.success(`Room ${response.data.roomNumber} added successfully!`);
      setFormData({
        hostelId: 1,
        title: "",
        price: "",
        maxPeople: 1,
        gender: "",
        floor: 0,
        description: "",
        roomNumber: "",
        amenities: {
          wardrobe: false,
          bathroom: false,
          toilet: false,
        },
        isAvailable: true,
      });
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="new">
      <ToastContainer />
      <div className="newContainer">
        <div className="bottom">
          <div className="right">
            <form onSubmit={handleSubmit}>
              <div className="formInput">
                <label htmlFor="hostel">Hostel:</label>
                <select
                  id="hostel"
                  name="hostelId"
                  value={formData.hostelId}
                  onChange={handleChange}
                  required
                >
                  <option value={1}>Showers Hostel</option>
                </select>
              </div>
              <div className="formInput">
                <label htmlFor="maxPeople">Room Type:</label>
                <select
                  id="maxPeople"
                  name="maxPeople"
                  value={formData.maxPeople}
                  onChange={handleChange}
                  required
                >
                  <option value={1}>1 in a room</option>
                  <option value={2}>2 in a room</option>
                  <option value={3}>3 in a room</option>
                </select>
              </div>
              <div className="formInput">
                <label htmlFor="gender">Gender:</label>
                <select
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div className="formInput">
                <label htmlFor="price">Price:</label>
                <input
                  id="price"
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  placeholder="eg: 3000"
                  required
                />
              </div>
              <div className="formInput">
                <label htmlFor="floor">Floor:</label>
                <select
                  id="floor"
                  name="floor"
                  value={formData.floor}
                  onChange={handleChange}
                  required
                >
                  <option value={0}>Ground floor</option>
                  <option value={1}>Top Floor</option>
                </select>
              </div>
              <div className="formInput">
                <label htmlFor="roomNumber">Room Number:</label>
                <input
                  id="roomNumber"
                  type="number"
                  name="roomNumber"
                  value={formData.roomNumber}
                  onChange={handleChange}
                  placeholder="eg: 101"
                  required
                />
              </div>
              <div className="formInput">
                <label htmlFor="description">Description:</label>
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Optional. "
                />
              </div>
              <div className="formInput amenities">
                <label>Amenities:</label>
                <div className="amenitiesGroup">
                  {Object.entries(formData.amenities).map(([key, value]) => (
                    <label key={key}>
                      <input
                        type="checkbox"
                        name={key}
                        checked={value}
                        onChange={handleChange}
                      />
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </label>
                  ))}
                </div>
              </div>
              <div className="formInput">
                <label>
                  <input
                    type="checkbox"
                    name="isAvailable"
                    checked={formData.isAvailable}
                    onChange={handleChange}
                  />
                  Available
                </label>
              </div>
              <div className="formInput">
                <label htmlFor="title">Title:</label>
                <input
                  id="title"
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  placeholder="Optional"
                />
              </div>
              <div className="formActions">
                <button type="button" onClick={back} disabled={isLoading}>
                  View All Rooms
                </button>
                <button type="submit" disabled={isLoading}>
                  {isLoading ? "Please Wait..." : "Add Room"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewRoom;
