import { useState, useEffect } from "react";
import axios from "axios";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import Navbar from "../../components/navbar/Navbar";
import List from "../../components/table/Table";
import Widget from "../../components/widget/Widget";
import Pagination from "../../components/Pagination";
import "./allbookings.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { useAuth } from "../../context/AuthContext";

const AllBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [bookingsPerPage] = useState(10);
  const { user } = useAuth();
  console.log(user);
  useEffect(() => {
    document.title = "Bookings - Admin";
    fetchBookings();
  }, []);

  const fetchBookings = async () => {
    try {
      const response = await axios.get("/api/bookings");
      setBookings(response.data);
    } catch (error) {
      console.error("Failed to fetch bookings:", error);
      setError("Failed to fetch bookings. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Get current bookings
  const indexOfLastBooking = currentPage * bookingsPerPage;
  const indexOfFirstBooking = indexOfLastBooking - bookingsPerPage;
  const currentBookings = bookings.slice(
    indexOfFirstBooking,
    indexOfLastBooking
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return (
      <div className="loading-container">
        <DotLottieReact
          src="https://lottie.host/83993069-1b11-4676-a63c-40334c08fc2e/Lu23ELkJ04.lottie"
          loop
          autoplay
        />
      </div>
    );
  }

  if (error) {
    return <div className="error-container">Error: {error}</div>;
  }

  return (
    <div className="single">
      <div className="showSidebar">
        <Sidebar />
      </div>
      <div className="singleContainer">
        <Navbar />
        <div className="widgets inrooms">
          <Widget type="bookings" />
          {user.isAdmin === "admin" && <Widget type="payments" />}{" "}
        </div>
        <div className="bottom">
          <h1 className="title">All Bookings: {bookings.length}</h1>
          <List
            data={currentBookings}
            tableType="bookings"
            userType={user.isAdmin}
          />
          <Pagination
            itemsPerPage={bookingsPerPage}
            totalItems={bookings.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default AllBookings;
