import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import NightShelterIcon from "@mui/icons-material/NightShelter";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

import "./widget.scss";

const WIDGET_TYPES = {
  bookings: {
    title: "BEDS BOOKED",
    isMoney: false,
    link: "View all Booked beds",
    href: "bookings",
    icon: (
      <PersonOutlinedIcon
        className="icon"
        style={{
          color: "crimson",
          backgroundColor: "rgba(255, 0, 0, 0.2)",
        }}
      />
    ),
  },
  rooms: {
    title: "AVAILABLE ROOMS",
    isMoney: false,
    link: "View available rooms",
    href: "rooms",
    icon: (
      <NightShelterIcon
        className="icon"
        style={{
          backgroundColor: "rgba(218, 165, 32, 0.2)",
          color: "goldenrod",
        }}
      />
    ),
  },
  hostels: {
    title: "HOSTELS",
    isMoney: false,
    link: "View all hostels",
    href: "hostels",
    icon: (
      <AccountBalanceWalletOutlinedIcon
        className="icon"
        style={{
          backgroundColor: "rgba(128, 0, 128, 0.2)",
          color: "purple",
        }}
      />
    ),
  },
  payments: {
    title: "REVENUE",
    isMoney: true,
    link: "Amount paid",
    href: "rooms/payments/all",
    icon: (
      <MonetizationOnOutlinedIcon
        className="icon"
        style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
      />
    ),
  },
};

const Widget = ({ type }) => {
  const [infoValue, setInfoValue] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const data = WIDGET_TYPES[type] || {};

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(`/api/${data.href}`);
        const info = response.data;
        setInfoValue(typeof info === "number" ? info : info.length);
      } catch (error) {
        // console.error(`Failed to fetch ${type} data:`, error);
        setError(`INTERNET DISCONECTED`);
      } finally {
        setIsLoading(false);
      }
    };

    if (data.href) {
      fetchData();
    }
  }, [type, data.href]);

  if (!data.title) {
    return <div>Invalid widget type</div>;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {/* {data.isMoney && "₵"} */}
          {isLoading ? (
            <DotLottieReact
              src="https://lottie.host/83993069-1b11-4676-a63c-40334c08fc2e/Lu23ELkJ04.lottie"
              loop
              autoplay
            />
          ) : error ? (
            "Error"
          ) : data.isMoney ? (
            `₵ ${infoValue}`
          ) : (
            infoValue
          )}
        </span>
        {!isLoading && (
          <Link to={`/${type}`} style={{ textDecoration: "none" }}>
            <span className="link">{data.link}</span>
          </Link>
        )}
      </div>
      <div className="right">
        <div className="percentage positive">
          <KeyboardArrowUpIcon />
          20%
        </div>
        {data.icon}
      </div>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default Widget;
