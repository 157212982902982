import React, { useMemo } from "react";
import dayjs from "dayjs";
import "./table.scss";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const getTableConfig = (tableType, data, onDelete, userType) => {
  switch (tableType) {
    case "users":
      return {
        headers: ["ID", "Username", "Phone Number", "Email Address", "Status"],
        rows: data.map((row) => (
          <TableRow key={row.id}>
            <TableCell className="tableCell">{row.id}</TableCell>
            <TableCell className="tableCell">
              <div className="cellWrapper">
                <img src={row.img} alt="" className="image" />
                {row.username}
              </div>
            </TableCell>
            <TableCell className="tableCell">{row.phone}</TableCell>
            <TableCell className="tableCell">{row.email}</TableCell>
            <TableCell className="tableCell">
              <span
                className={`status ${
                  row.isAdmin === 1 ? "Approved" : "Pending"
                }`}
              >
                {row.isAdmin === 1 ? "Admin" : "User"}
              </span>
            </TableCell>
          </TableRow>
        )),
      };

    case "rooms":
      return {
        headers: [
          "Room number",
          "Max People",
          "Bed Price",
          "Beds Left",
          "Sex",
          "Actions",
        ],
        rows: data.map((row) => {
          let amenities;
          amenities = JSON.parse(row.amenities);
          let newAmenities = JSON.parse(amenities);
          let bedsLeft = row.maxPeople - row.booking_count;

          return (
            <TableRow key={row.id}>
              <TableCell className="tableCell">{row.roomNumber}</TableCell>
              <TableCell className="tableCell">
                {row.maxPeople} in a room with:
                {Object.keys(newAmenities).length === 0
                  ? " only bed"
                  : Object.keys(newAmenities).join(", ")}
              </TableCell>
              <TableCell className="tableCell">{row.price}</TableCell>
              <TableCell className="tableCell">
                <p
                  className={`status ${
                    row.booking_status === "Full" ? "Pending" : "Approved"
                  }`}
                >
                  {" "}
                  {`${bedsLeft} / ${row.maxPeople}`}
                </p>
              </TableCell>
              <TableCell className="tableCell">
                {row.gender === "Male" ? "M" : "F"}
              </TableCell>
              <TableCell className="tableCell">
                <span
                  className="status Delete"
                  onClick={() => onDelete(row.roomNumber)}
                  style={{ cursor: "pointer", color: "red" }}
                >
                  Delete
                </span>
              </TableCell>
            </TableRow>
          );
        }),
      };

    case "bookings":
      return {
        headers: [
          "Room Number",
          "Type",
          "First Name",
          "Last Name",
          "Booking Date",
          userType === "admin" && "Amount",
        ],
        rows: data.map((row) => {
          let amenities;
          amenities = JSON.parse(row.amenities);
          let newAmenities = JSON.parse(amenities);

          const formatDateTime = (dateString) => {
            return dayjs(dateString).format("DD/MM/YY HH:mm:ss");
          };

          return (
            <TableRow key={row.id}>
              <TableCell className="tableCell">{row.room_number}</TableCell>
              <TableCell className="tableCell">
                {row.maxPeople} in a room with{" "}
                {Object.keys(newAmenities).length === 0
                  ? " only bed"
                  : Object.keys(newAmenities).join(", ")}
              </TableCell>
              <TableCell className="tableCell">{row.user_fname}</TableCell>
              <TableCell className="tableCell">{row.user_lname}</TableCell>
              <TableCell className="tableCell">
                {formatDateTime(row.booking_date)}
              </TableCell>
              {userType === "admin" && (
                <TableCell classname="tableCell">{row.price}</TableCell>
              )}
            </TableRow>
          );
        }),
      };

    case "payments":
      return {
        headers: ["Amount", "Method", "Date", "Reference", "Status"],
        rows: data.map((row) => {
          const formatDateTime = (dateString) => {
            return dayjs(dateString).format("DD/MM/YY HH:mm:ss");
          };

          return (
            <TableRow key={row.id}>
              <TableCell className="tableCell">{row.amount}</TableCell>
              <TableCell className="tableCell">{row.payment_method}</TableCell>
              <TableCell className="tableCell">
                {formatDateTime(row.payment_date)}
              </TableCell>
              <TableCell className="tableCell">{row.reference}</TableCell>
              <TableCell className="tableCell">
                <span
                  className={`status ${
                    row.status === "Paid" ? "Approved" : "Pending"
                  }`}
                >
                  {row.status === "Paid" ? "Paid" : "Pending"}
                </span>
              </TableCell>
            </TableRow>
          );
        }),
      };

    default:
      return { headers: [], rows: [] };
  }
};

const List = ({ tableType, data, onDelete, userType }) => {
  const { headers, rows } = useMemo(
    () => getTableConfig(tableType, data, onDelete, userType),
    [tableType, data, onDelete, userType]
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <TableContainer component={Paper} className="table">
      <Table
        sx={{ minWidth: isMobile ? "100%" : 650 }}
        aria-label="simple table"
        size={isMobile ? "small" : "medium"}
      >
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header} className="tableCell">
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
