import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { useAuth } from "./context/AuthContext";

function ProtectedRoute({ children }) {
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return (
      <div>
        <DotLottieReact
          src="https://lottie.host/743b1cd3-27c7-458b-a21c-884e1811a1a6/FTV12V1xzG.lottie"
          loop
          autoplay
        />
      </div>
    );
  }

  if (!isAuthenticated) {
    // Redirect to login page, but save the current location
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // if (user && user.type === "manager") {
  //   // Redirect non-managers to home page
  //   return <Navigate to="/rooms" replace />;
  // }

  return children;
}

export default ProtectedRoute;
